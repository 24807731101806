export class Enumeration {
    name;
    value;
    static enumerations = new Map();
    static fromName(name) {
        for (const [enumMember] of Enumeration.enumerations) {
            if (enumMember.name.toLowerCase() === name.toLowerCase()) {
                return enumMember;
            }
        }
        throw new Error(`Enumeration name ${name} not support.`);
    }
    static membersOf(type) {
        return Array.from(Enumeration.enumerations.keys())
            .filter(member => member instanceof type)
            .map(member => member.name);
    }
    static fromValue(value) {
        for (const [enumMember, enumValue] of Enumeration.enumerations) {
            if (enumValue === value) {
                return enumMember;
            }
        }
        throw new Error(`Enumeration value ${value} not support.`);
    }
    constructor(name, value) {
        this.name = name;
        this.value = value;
        Enumeration.enumerations.set(this, value);
    }
    equals(other) {
        if (other === undefined || other === null)
            return false;
        return this.value === other.value;
    }
    toString() {
        return this.name;
    }
}
