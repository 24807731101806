import { RangeBase } from '../abstractions';
export class DateTimeRange extends RangeBase {
    static create(start, end) {
        return new DateTimeRange(start, end);
    }
    constructor(start, end) {
        super(start, end);
    }
    toTimestampTuple(timeConverter, length) {
        const maxLength = length || 8;
        const timestamps = [];
        let current = this.start.clone();
        let final = this.end.clone();
        if (this.end.minute() > 0) {
            final = this.end.set('hours', this.end.hour()).set('minute', 0);
        }
        const hoursDiff = final.diff(current, 'hours');
        const hourlyIncrement = Math.ceil(hoursDiff / (maxLength - 1));
        while (current.isBefore(this.end) || current.isSame(this.end)) {
            timestamps.push(timeConverter(current));
            if (current.minute() > 0) {
                current = current.add(60 - current.minute(), 'minutes');
            }
            else {
                current = current.add(hourlyIncrement, 'hours');
            }
            if (timestamps.length >= maxLength - 1) {
                break;
            }
        }
        timestamps.push(timeConverter(this.end));
        return timestamps;
    }
}
