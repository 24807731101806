export class RangeBase {
    start;
    end;
    constructor(start, end) {
        this.start = start;
        this.end = end;
    }
    toTuple() {
        return [this.start, this.end];
    }
}
