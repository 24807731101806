import { RangeBase } from '../abstractions';
export class NumericRange extends RangeBase {
    static create(start, end) {
        return new this(start, end);
    }
    static fromArray(values) {
        if (values.length < 2) {
            throw new Error('Array must have at least two elements.');
        }
        return this.create(Math.max(...values), Math.min(...values));
    }
    constructor(start, end) {
        super(start, end);
    }
}
