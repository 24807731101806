import { Enumeration } from '../abstractions';
export class InstrumentType extends Enumeration {
    constructor(name, value) {
        super(name, value);
    }
    static Unknown = new InstrumentType('Unknown Instrument', 0);
    static Future = new InstrumentType('Futures', 1);
    static Index = new InstrumentType('Index', 2);
    static TaiwanStocks = new InstrumentType('Stocks', 3);
    static TaiwanOptions = new InstrumentType('Option', 4);
    static OverseaStock = new InstrumentType('OS_Stock', 5);
    static CryptoCurrency = new InstrumentType('CryptoCurrency', 6);
    static ForeX = new InstrumentType('ForeX', 7);
    static OverseaIndex = new InstrumentType('OS_Index', 8);
}
