import dayAPI from '~/utils/dayAPI';
import { ValueObject } from '../abstractions';
export class TimeOnly extends ValueObject {
    time;
    constructor(value) {
        super();
        if (!TimeOnly.isValidTimeFormat(value)) {
            throw new Error('Invalid time format.');
        }
        const parts = value.split(':');
        const hour = Number(parts[0]);
        const minute = Number(parts[1]);
        const second = parts.length === 3 ? Number(parts[2]) : 0;
        if (hour < 0 || hour > 23) {
            throw new Error('Hour is out of range. Hour should be between 0 and 23.');
        }
        if (minute < 0 || minute > 59) {
            throw new Error('Minute is out of range. Minute should be between 0 and 59.');
        }
        if (second < 0 || second > 59) {
            throw new Error('Second is out of range. Second should be between 0 and 59.');
        }
        this.time = dayAPI().hour(hour).minute(minute).second(second).millisecond(0);
    }
    equals(other) {
        if (!(other instanceof TimeOnly)) {
            return false;
        }
        return this.hour === other.hour && this.minute === other.minute && this.second === other.second;
    }
    static parse(timeString) {
        return new TimeOnly(timeString);
    }
    toString() {
        return this.time.format('HH:mm:ss');
    }
    get hour() {
        return this.time.hour();
    }
    get minute() {
        return this.time.minute();
    }
    get second() {
        return this.time.second();
    }
    isSameOrAfter(time) {
        return this.equals(time) || this.isAfter(time);
    }
    isSameOrBefore(time) {
        return this.equals(time) || this.isBefore(time);
    }
    /** > */
    isAfter(time) {
        if (this.hour !== time.hour) {
            return this.hour > time.hour;
        }
        if (this.minute !== time.minute) {
            return this.minute > time.minute;
        }
        return this.second > time.second;
    }
    /** < */
    isBefore(time) {
        if (this.hour !== time.hour) {
            return this.hour < time.hour;
        }
        if (this.minute !== time.minute) {
            return this.minute < time.minute;
        }
        return this.second < time.second;
    }
    /** > = */
    isBetween(startTime, endTime) {
        return this.isAfter(startTime) && this.isBefore(endTime);
    }
    /** <= */
    isSameOrBetween(startTime, endTime) {
        return this.isBetween(startTime, endTime) || this.equals(startTime) || this.equals(endTime);
    }
    static isValidTimeFormat(value) {
        return /^\d{2}:\d{2}(:\d{2})?$/.test(value);
    }
}
