export class Entity {
    id;
    constructor(id) {
        this.id = id;
    }
    equals(other) {
        if (other === undefined || other === null)
            return false;
        return this.id.equals(other.id) ? true : false;
    }
}
