import { ValueObject } from '../abstractions';
export class TradingSessionTime extends ValueObject {
    dayOfWeek;
    time;
    static create(dayOfWeek, time) {
        if (dayOfWeek > 6 || dayOfWeek < 0)
            throw new Error('Invalid day of week');
        return new TradingSessionTime(dayOfWeek, time);
    }
    constructor(dayOfWeek, time) {
        super();
        this.dayOfWeek = dayOfWeek;
        this.time = time;
    }
    toString() {
        return `TradingSessionTime(dayOfWeek=${this.dayOfWeek}, time=${this.time.toString()})`;
    }
}
