import { ValueObject } from '../abstractions';
export class InstrumentSymbol extends ValueObject {
    value;
    static MAX_LENGTH = 64;
    static create(value) {
        if (value.length === 0)
            throw new Error('Invalid symbol');
        if (value.length > this.MAX_LENGTH)
            throw new Error('Symbol is too long.');
        return new InstrumentSymbol(value);
    }
    constructor(value) {
        super();
        this.value = value;
    }
}
