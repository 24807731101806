import dayAPI from '~/utils/dayAPI';
import { ValueObject } from '../abstractions';
import { DateTimeRange } from './datetime-range';
import { TimeOnly } from './time-only';
class TradingSessionHelper {
    static alignOpenTime(datetime, openingTime) {
        let openDaysDiff = 0;
        if (datetime.weekday() < openingTime.dayOfWeek) {
            const daysDiff = Math.abs(openingTime.dayOfWeek - datetime.weekday());
            if (daysDiff > 1) {
                openDaysDiff = openingTime.dayOfWeek - (7 + datetime.weekday());
            }
            else {
                openDaysDiff = openingTime.dayOfWeek - datetime.weekday();
            }
        }
        else if (datetime.weekday() > openingTime.dayOfWeek) {
            const daysDiff = Math.abs(openingTime.dayOfWeek - datetime.weekday());
            if (daysDiff > 1) {
                openDaysDiff = 7 + openingTime.dayOfWeek - datetime.weekday();
            }
            else {
                openDaysDiff = openingTime.dayOfWeek - datetime.weekday();
            }
        }
        return datetime
            .clone()
            .set('hours', openingTime.time.hour)
            .set('minutes', openingTime.time.minute)
            .set('seconds', openingTime.time.second)
            .set('millisecond', 0)
            .set('date', datetime.date() + openDaysDiff);
    }
    static alignCloseTime(datetime, closingTime) {
        let closeDaysDiff = 0;
        if (datetime.weekday() < closingTime.dayOfWeek) {
            const daysDiff = Math.abs(closingTime.dayOfWeek - datetime.weekday());
            if (daysDiff > 1) {
                closeDaysDiff = -(7 + datetime.weekday() - closingTime.dayOfWeek);
            }
            else {
                closeDaysDiff = Math.abs(closingTime.dayOfWeek - datetime.weekday());
            }
        }
        else if (datetime.weekday() > closingTime.dayOfWeek) {
            const daysDiff = datetime.weekday() - closingTime.dayOfWeek;
            if (daysDiff > 1) {
                closeDaysDiff = closingTime.dayOfWeek + 7 - datetime.weekday();
            }
            else {
                closeDaysDiff = -daysDiff;
            }
        }
        return datetime
            .clone()
            .set('hours', closingTime.time.hour)
            .set('minutes', closingTime.time.minute)
            .set('seconds', closingTime.time.second)
            .set('millisecond', 0)
            .set('date', datetime.date() + closeDaysDiff);
    }
}
export class TradingSession extends ValueObject {
    openingTime;
    closingTime;
    static create(openingTime, closingTime) {
        if (openingTime.dayOfWeek === closingTime.dayOfWeek &&
            closingTime.time.isSameOrBefore(openingTime.time)) {
            throw new Error('Closing time should be after opening time in same day');
        }
        return new TradingSession(openingTime, closingTime);
    }
    constructor(openingTime, closingTime) {
        super();
        this.openingTime = openingTime;
        this.closingTime = closingTime;
    }
    isOpen(date) {
        const baseTime = date || dayAPI();
        const time = TimeOnly.parse(baseTime.format('HH:mm:ss'));
        if (baseTime.weekday() === this.openingTime.dayOfWeek &&
            baseTime.weekday() === this.closingTime.dayOfWeek) {
            return time.isSameOrAfter(this.openingTime.time) && time.isBefore(this.closingTime.time);
        }
        else {
            if (baseTime.weekday() === this.openingTime.dayOfWeek) {
                return time.isSameOrAfter(this.openingTime.time);
            }
            if (baseTime.weekday() === this.closingTime.dayOfWeek) {
                return time.isBefore(this.closingTime.time);
            }
        }
        return false;
    }
    toRange(datetime) {
        const baseTime = datetime || dayAPI();
        return DateTimeRange.create(TradingSessionHelper.alignOpenTime(baseTime, this.openingTime), TradingSessionHelper.alignCloseTime(baseTime, this.closingTime));
    }
    toString() {
        return `TradingSession(openingTime=${this.openingTime.toString()}, closingTime=${this.closingTime.toString()})`;
    }
    splitAsTimestamps(datetime, converter, length) {
        const baseTime = datetime || dayAPI();
        const range = this.toRange(baseTime);
        const maxLength = length && length > 10 ? 10 : length || 10;
        const totalMinutes = range.end.diff(range.start, 'minute');
        const hourGap = Math.ceil(totalMinutes / 60 / (maxLength - 1));
        const firstHour = range.start.minute() > 0 ? range.start.add(1, 'hour').startOf('hour') : range.start;
        const lastHour = range.end.startOf('hour');
        const timestamps = [
            range.start,
            ...Array.from(new Array(maxLength - 2), (_, i) => firstHour.add((1 + i) * hourGap, 'hour')).filter(t => t <= lastHour.subtract(Math.ceil(hourGap / 2), 'hour')),
            lastHour,
        ];
        return timestamps.map(ts => converter(ts));
    }
}
export class IntradayTradingSessions extends ValueObject {
    datetime;
    name;
    sessions = [];
    constructor(datetime, name) {
        super();
        this.datetime = datetime;
        this.name = name;
    }
    toRange() {
        return DateTimeRange.create(TradingSessionHelper.alignOpenTime(this.datetime.clone(), this.first().openingTime), TradingSessionHelper.alignCloseTime(this.datetime.clone(), this.last().closingTime));
    }
    add(session) {
        if (!this.sessions.find(item => item.equals(session)))
            this.sessions.push(session);
        return this;
    }
    insert(session, index) {
        this.sessions.splice(index, 0, session);
    }
    take(index) {
        const session = this.sessions.at(index);
        if (!session) {
            throw new Error('Session index out of range.');
        }
        return session;
    }
    addRange(sessions) {
        sessions.forEach(session => this.add(session));
        return this;
    }
    count(predicate) {
        if (predicate) {
            return this.where(predicate).count();
        }
        return this.sessions.length;
    }
    forEach(action) {
        this.sessions.forEach((session, index) => {
            action(session, index, this.sessions);
        });
    }
    any(predicate) {
        if (predicate) {
            return this.sessions.some(predicate);
        }
        return this.sessions.length > 0;
    }
    firstOrDefault() {
        if (this.sessions.length > 0) {
            return this.sessions[0];
        }
        return null;
    }
    lastOrDefault() {
        if (this.sessions.length > 0) {
            return this.sessions[this.sessions.length - 1];
        }
        return null;
    }
    first() {
        if (this.sessions.length > 0) {
            return this.sessions[0];
        }
        throw new Error('No elements in the collection.');
    }
    last() {
        if (this.sessions.length > 0) {
            return this.sessions[this.sessions.length - 1];
        }
        throw new Error('No elements in the collection.');
    }
    where(predicate) {
        const filteredSessions = this.sessions.filter(predicate);
        const newCollection = new IntradayTradingSessions(this.datetime, this.name);
        newCollection.addRange(filteredSessions);
        return newCollection;
    }
    splitAsTimestamps(converter, length) {
        const range = this.toRange();
        const maxLength = length ? length : 10;
        const totalMinutes = range.end.diff(range.start, 'minute');
        const hourGap = Math.ceil(totalMinutes / 60 / (maxLength - 1));
        const firstHour = range.start.minute() > 0 ? range.start.add(1, 'hour').startOf('hour') : range.start;
        const lastHour = range.end.startOf('hour');
        const timestamps = [
            range.start,
            ...Array.from(new Array(maxLength - 2), (_, i) => firstHour.add((1 + i) * hourGap, 'hour')).filter(t => t <= lastHour.subtract(Math.ceil(hourGap / 2), 'hour')),
            lastHour,
        ];
        return timestamps.map(ts => converter(ts));
    }
}
